'use client'

import { Cell, Spacer, Text } from '@vinted/web-ui'
import { ReactNode } from 'react'

import { HorizontalScrollArea } from '@marketplace-web/shared/ui-helpers'
import { Skeleton } from '@marketplace-web/shared/skeleton'
import AspectRatio from 'components/AspectRatio'

import DynamicSpacer from '../DynamicSpacer'

type Props = {
  suffix?: ReactNode
}

const ClosetSkeleton = ({ suffix }: Props) => {
  const renderCaption = (width: number) => (
    <Text width={Text.Width.Parent} as="span" type={Text.Type.Caption}>
      <Skeleton height={12} width={width} />
    </Text>
  )

  const itemBoxSkeleton = (
    <HorizontalScrollArea.Item className="closet__item">
      <div className="new-item-box__container">
        <AspectRatio ratio="2:3">
          <Skeleton height="100%" />
        </AspectRatio>
        <Cell styling={Cell.Styling.Narrow}>
          {renderCaption(96)}
          {renderCaption(72)}
          <Spacer />
          {renderCaption(64)}
          {renderCaption(72)}
        </Cell>
      </div>
    </HorizontalScrollArea.Item>
  )

  return (
    <>
      <DynamicSpacer phones="Regular" tabletsUp="Large" />
      <div className="closet-container">
        <Cell styling={Cell.Styling.Tight}>
          <div className="closet-container__item-horizontal-scroll">
            <div className="closet closet--with-horizontal-scroll closet--wide">
              <Cell
                prefix={<Skeleton width={48} height={48} circle />}
                title={<Skeleton width={96} height={20} />}
                body={<Skeleton width={120} height={20} />}
                suffix={<Skeleton width={120} height={32} />}
              />
              <HorizontalScrollArea
                controlsScrollType={HorizontalScrollArea.ControlScrollType.Partial}
                showControls={false}
              >
                {itemBoxSkeleton}
                {itemBoxSkeleton}
                {itemBoxSkeleton}
                {itemBoxSkeleton}
                {itemBoxSkeleton}
                {itemBoxSkeleton}
              </HorizontalScrollArea>
            </div>
          </div>
        </Cell>
      </div>
      <div className="u-ui-padding-left-medium">
        <Spacer />
        <Skeleton width={140} height={18} />
      </div>
      {suffix}
    </>
  )
}

export default ClosetSkeleton
