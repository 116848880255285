class InvariantError extends Error {
  constructor(message?: string, cause?: unknown) {
    super(message, { cause })
    this.name = 'InvariantError'
  }
}

export default function invariant(
  condition: unknown,
  messageOrError: string | Error = '',
): asserts condition {
  if (condition) return

  throw messageOrError instanceof Error
    ? new InvariantError(messageOrError.message, messageOrError)
    : new InvariantError(messageOrError)
}
